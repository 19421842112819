import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Logo, SEO } from 'components';
import './thank-you.scss';

class ThankYou extends Component {
  render() {
    return (
      <section className="success-page">
        <SEO title="Welcome to Hudle - Hudle" />
        <div className="wrapper">
          <Logo />
          <p>
            Welcome to Hudle. We are setting up your account and you will receive a welcome email in the next 24 hours.
          </p>
          <Link to="/" className="button outline-white">
            Go back home
          </Link>
        </div>
      </section>
    );
  }
}

export default ThankYou;
